import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

import "../index.scss"

import ImgLogo from "../../images/logo-tanecni-zlonin.svg"

import "../normalize.css"

import Bloglist from "../../components/bloglist/bloglist"
import Footer from "../../components/footer/footer"

const Article = () => {
	return (

		<Layout>
			<Seo title="Nový kurz Latin Fever for ladies" />

			<div className="container container--article mb3">

				<Link to="/" data-sal="fade" data-sal-duration="1000">
					<img src={ImgLogo} alt="Taneční Zlonín logo" className="tz-miniheader__logo" />
				</Link>

				<h1 className="tz-miniheader__h1" data-sal="fade" data-sal-duration="1000">
					Nový kurz Latin Fever for&nbsp;ladies
				</h1>

				<h2 className="mt1 mb3" data-sal="fade" data-sal-duration="1000">Pro všechny ženy a&nbsp;slečny, které si chtějí zatancovat bez&nbsp;partnera</h2>

				<div>
					
					<p data-sal="fade" data-sal-duration="1000">Zvu Vás na nový kurz Latin Fever - pro všechny ženy a slečny, které si chtějí zatancovat bez partnera 💃💃💃</p>
					<ul data-sal="fade" data-sal-duration="1000">
						<li>kdy: každou neděli 18-19 hod</li>
						<li>začínáme: 6. března 2022</li>
						<li>kde: velký sál KC Zlonín</li>
						<li>cena: 180,-/lekci</li>
					</ul>

					<p data-sal="fade" data-sal-duration="1000">Pro přihlášení mě kontaktujte:</p>
					<ul data-sal="fade" data-sal-duration="1000">
						<li>na emailu <a href="mailto:tanecni.zlonin@gmail.com">tanecni.zlonin@gmail.com</a></li>
						<li>na messengeru stránky <a href="https://www.facebook.com/tanecni.zlonin" target="_blank" rel="noreferrer">Taneční&nbsp;Zlonín</a></li>
						<li>na telefonu/WhatsApp <a href="tel:+420732560838">+420 732 560 838</a></li>
					</ul>

					<p data-sal="fade" data-sal-duration="1000">Latin Fever je kurz latinsko-amerických tanců, na kterém si postupně osvojíme tance Cha-Cha-Cha, Samba, Jive, Rumba, Salsa, Paso Doble. Naučíme se propojit pohyb s elegancí, smyslností a temperamentem 🔥 a to vše na krásnou latinskou hudbu 🎶</p>
					<p data-sal="fade" data-sal-duration="1000">Odreagujete se od každodenní rutiny, zbavíte se přebytečného stresu a načerpáte pořádnou dávku pozitivní energie ☀️</p>
					<p data-sal="fade" data-sal-duration="1000">Kurz latiny je mimo jiné skvělá varianta kardio cvičení, které dokonale formuje postavu 💪</p>
					<p data-sal="fade" data-sal-duration="1000">Přijďte si se mnou zatančit, těším se na vás ☺️💃❤<br />Irina</p>

					<hr data-sal="fade" data-sal-duration="1000" />

					<p data-sal="fade" data-sal-duration="1000">O mně:</p>
					<ul data-sal="fade" data-sal-duration="1000">
						<li>➡️ profesionální tanečnice a&nbsp;trenérka</li>
						<li>➡️ semifinalistka Mistrovství Evropy a&nbsp;Mistrovství světa v&nbsp;Professional Latin Showdance</li>
						<li>➡️ vicemistryňe republiky v&nbsp;salse</li>
					</ul>

					<p data-sal="fade" data-sal-duration="1000">Více o mně na:</p>
					<ul data-sal="fade" data-sal-duration="1000">
						<li>FB: <a href="https://www.facebook.com/irina.maizlish" target="_blank" rel="noreferrer">https://www.facebook.com/irina.maizlish</a></li>
						<li>Instagram: <a href="https://www.instagram.com/irinamaizlish" target="_blank" rel="noreferrer">https://www.instagram.com/irinamaizlish</a></li>
					</ul>

				</div>

			</div>

			<Bloglist></Bloglist>
			<Footer></Footer>

		</Layout>

	)
}

export default Article